import React from 'react';
import withRouter from './hooks/hooks';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import PricingContentCard from './components/PricingContentCard';

class Pricing extends React.Component {

    componentDidMount(): void {
        document.title = 'THECODE.ACADEMY - PRICING POLICY';
    }
    
    render() {
        return (<div>
            <Container maxWidth="xl">
                <Box sx={{
                    display: {xs:'block', md: 'flex'},
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    p: 1,
                    m: 1,
                    }}>
                        <Box sx={{ p:1,m:1, minWidth:320, flexGrow: 1}}>
                            <PricingContentCard title="PRICING" />
                        </Box>
                </Box>
                
            </Container>
        </div>)
    }
}
export default withRouter(Pricing);