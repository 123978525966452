import React from 'react';
import { observer } from 'mobx-react';

import axios from 'axios';

import withRouter from './hooks/hooks';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import BasicReviewCard from './components/BasicReviewCard';

import PageHandler from './handlers/PageHandler';

interface HomeProps {
    router:any,
}

@observer
class Home extends React.Component<HomeProps> {

    pageHandler = new PageHandler();

    componentDidMount(): void {

        axios.get('/jsondata/home/index.json')
            .then((response) => {
                console.log(response);
                this.pageHandler.fillData(response.data);
                document.title = 'THECODE.ACADEMY - ' + this.pageHandler.pageData.pageTitle;
            })
            .catch((error) => {
                //TODO: Something...
                console.log(error);
            });
    }
    
    render() {
        return (<div>
            <Container sx={{paddingTop: 4}} maxWidth="xl">
                <Grid sx={{paddingLeft:4,paddingRight:4}} container spacing={2}>
                    {this.pageHandler.contentData.collection?.map((_record:any, _idx:number) => {
                    return <Grid key={_idx} item xs={12}  md={6} lg={4}>
                            <BasicReviewCard title={_record.title} data={_record} />
                            </Grid>;
                    })}
                </Grid>
                
            </Container>
        </div>)
    }
}
export default withRouter(Home);