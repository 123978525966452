import * as React from 'react';
import { ReactSVG } from 'react-svg';

import axios from 'axios';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import HiveIcon from '@mui/icons-material/Hive';

import BasicReviewCard from './BasicReviewCard';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface TrainingsContentCardProps {
    title: string,
    data:any
  }

class TrainingsContentCard extends React.Component<TrainingsContentCardProps> {
  
  state:any = {
    expanded: false,
    pageContentMarkdown: ''
  };

  handleExpandClick = () => {
    this.setState({expanded:!this.state.expanded});
  }

  componentDidMount(): void {
    const promises = [];

    if(this.props.data.pageData.pageContentMd){

      axios.get(this.props.data.pageData.pageContentMd)
            .then((response) => {
                console.log(response);
                this.setState({pageContentMarkdown: response.data});
            })
            .catch((error) => {
                //TODO: Something...
                console.log(error);
            });
    }

  }

  render() {
    
    return (
      <Card sx={{ flexGrow: 1 }} square={true} elevation={0}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: '#00add7' }} aria-label="recipe">
              <HiveIcon />
            </Avatar>
          }
          title={<Typography sx={{fontWeight: 600, fontSize: 24}}>{this.props.title?.toUpperCase()}</Typography>}
          subheader={this.props.data.pageData.pageSubtitle}
        />
        <CardContent
          sx={{minHeight:250, 
              display:'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}
        >
          {this.props.data.pageData.pageBannerSvg && <ReactSVG 
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'height: ' + this.props.data.pageData.pageBannerSvgHeight);
              
            }}
            afterInjection={(error) => {
              if (error) {
                console.error(error)
                return
              }
            }}
            src={this.props.data.pageData.pageBannerSvg} />}
      </CardContent>
        <CardContent>
          <Typography variant="body2" component="div" color="text.secondary">
           {this.props.data.pageData.pageContentMd && <ReactMarkdown children={this.state.pageContentMarkdown} remarkPlugins={[remarkGfm]} />}
           {!this.props.data.pageData.pageContentMd && this.props.data.pageData.pageContent}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
            <Box
              sx={{display:'flex', flexGrow:1, justifyContent: 'center'}}
              aria-expanded={this.state.expanded}
               aria-label="show more"
            >
              <Button disableElevation variant="outlined" onClick={this.handleExpandClick}>{this.state.expanded? 'LESS':'MORE'} INFO PLEASE</Button>
            </Box>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
          <Grid container spacing={2}>
            {this.props.data.contentData.collection?.map((_record:any, _idx:number) => {
              return <Grid key={_idx} item xs={12}  md={6} lg={4}>
                      <BasicReviewCard title={_record.title} data={_record} />
                    </Grid>;
            })}
          </Grid>
          </CardContent>
        </Collapse>
      </Card>)

  }
}
export default TrainingsContentCard;