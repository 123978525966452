import React from 'react';

import axios from 'axios';

import Component from './components/Component';
import TcaAppBar from './TcaAppBar';

import Home from './Home';
import Trainings from './Trainings';
import Pricing from './Pricing';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

class App extends React.Component {

  state:any = {};

  constructor(props:any){
    super(props);


    this.state = {
      navRecords: []
    }

  }

  componentDidMount(): void {
    
    axios.get('/jsondata/index.json')
    .then((response) => {
      console.log(response);
      this.setState({navRecords: response.data.nav});
    })
    .catch((error) => {
      //TODO: Something...
      console.log(error);
    });
    
  }

  _resolveElement: any | null = (_element:string) => {
    const _map:any = {
      "Home": Home,
      "Trainings": Trainings,
      "Pricing": Pricing
    }

    return _map[_element] ;
  }

  _renderRoute = (_record:any) => {
    return <Component as={this._resolveElement(_record.component)} />;
  }
  
  render() {

    return (
      <div>
        {this.state.navRecords && <Router>
          <TcaAppBar navData={this.state.navRecords} />
          <Routes>
            {this.state.navRecords
              .map((record:any, _idx:number) => <Route key={_idx} path={record.route} element={this._renderRoute(record)} />)
            }
          </Routes>
        </Router>}
      </div>
    );

  }
}
export default App;
