import * as React from 'react';

import { ReactSVG } from 'react-svg';

import axios from 'axios';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';

import HiveIcon from '@mui/icons-material/Hive';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import OutboxIcon from '@mui/icons-material/Outbox';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

interface TrainingContentProps {
    course_id: string,
    data: any;
  }




class TrainingContent extends React.Component<TrainingContentProps> {

  state:any = {
    moreInfoFormEnabled: false
  };

  componentDidMount(): void {

    if(this.props.course_id){

      const promises = [];

      if(this.props.data?.pageData.pageContentMd){
        promises.push(axios.get(`${this.props.data.pageData.pageContentMd}`));
      } else {
        promises.push(axios.get('/markdowndata/empty.md'));
      }
  
      if(this.props.data?.pageData.pageInfosMd){
        promises.push(axios.get(`${this.props.data.pageData.pageInfosMd}`));
      } else {
        promises.push(axios.get('/markdowndata/empty.md'));
      }

        Promise.all(promises).then(results => {

          console.log(results);
          
          this.setState({
            pageContentMarkdown: results[0].data,
            pageInfosMarkdown: results[1].data,
          });

        });
      }

  }
  
  componentDidUpdate(prevProps:TrainingContentProps){

    if(this.props.course_id !== prevProps.course_id){

      if(this.props.course_id){

        const promises = [];
  
        if(this.props.data?.pageData.pageContentMd){
          promises.push(axios.get(`${this.props.data.pageData.pageContentMd}`));
        } else {
          promises.push(axios.get('/markdowndata/empty.md'));
        }
    
        if(this.props.data?.pageData.pageInfosMd){
          promises.push(axios.get(`${this.props.data.pageData.pageInfosMd}`));
        } else {
          promises.push(axios.get('/markdowndata/empty.md'));
        }
  
          Promise.all(promises).then(results => {
  
            console.log(results);
            
            this.setState({
              pageContentMarkdown: results[0].data,
              pageInfosMarkdown: results[1].data,
            });
  
          });
        }

    }

  }


  handleMoreInfos = () => {
    this.setState({
      moreInfoFormEnabled: true
    });
  }

  render() {
    return (
      <Card sx={{ flexGrow: 1 }} square={true} elevation={0}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: '#00add7' }} aria-label="recipe">
              <HiveIcon />
            </Avatar>
          }
          title={<Typography sx={{fontWeight: 600, fontSize: 24}}>{this.props.course_id.toUpperCase()}</Typography>}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item  xs={12}  md={8} lg={9}>
              <Typography variant="body2" component="div" color="text.secondary">
                {this.state.pageContentMarkdown && <ReactMarkdown children={this.state.pageContentMarkdown} remarkPlugins={[remarkGfm]} />}
                {!this.state.pageContentMarkdown && this.props.data?.pageData.pageContent}
              </Typography>
            </Grid>
            <Grid item  xs={12}  md={4} lg={3}>
              <Stack spacing={2}>
                <Item sx={{
                  backgroundColor: this.props.data.pageData.pageInfosSvgBackground,
                  minHeight:(this.props.data.pageData.pageInfosSvgHeight+20),
                  display:'flex',
                  justifyContent: 'center',
                  alignItems: 'center',}} square={true} elevation={0}>
                {this.props.data.pageData.pageInfosSvg && <ReactSVG 
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'height: ' + this.props.data.pageData.pageInfosSvgHeight);
                  }}
                  afterInjection={(error) => {
                    if (error) {
                      console.error(error)
                      return
                    }
                  }}
                  src={this.props.data.pageData.pageInfosSvg} />}
                </Item>
                {!this.state.moreInfoFormEnabled && <Button 
                    sx={{
                      color: this.props.data.pageData.moreInfosButtonColor
                    }}
                    onClick={this.handleMoreInfos} 
                    variant="text" 
                    startIcon={<FavoriteBorderIcon />}>
                      {this.props.data.pageData.moreInfosButtonText}
                  </Button>}
                {this.state.moreInfoFormEnabled && <Item sx={{
                  backgroundColor: this.props.data.pageData.sendAMessageRequestBackground,
                  minHeight:100,
                  display:'flex'}} square={true} elevation={0}>
                    <Stack spacing={2}>
                      <Typography variant="body2" component="div" color={this.props.data.pageData.sendAMessageRequestTextColor}>{this.props.data.pageData.sendAMessageRequestText}</Typography>
                      <TextField sx={{
                        '& label.Mui-focused': {
                          color: this.props.data.pageData.sendAMessageTextareaFontColor,
                        },
                        '& .MuiFilledInput-root': {
                          fontSize: '0.89rem',
                          borderColor: this.props.data.pageData.sendAMessageTextareaBgColor,
                          color: this.props.data.pageData.sendAMessageTextareaFontColor,
                          backgroundColor: this.props.data.pageData.sendAMessageTextareaBgColor,
                          '&:after': {
                            borderBottomColor: this.props.data.pageData.sendAMessageTextareaBgColor,
                          },
                          '&:hover': {
                            borderColor: 'yellow',
                          },
                          '&.Mui-focused': {
                            backgroundColor: this.props.data.pageData.sendAMessageTextareaBgColor,
                            borderColor: this.props.data.pageData.sendAMessageTextareaBgColor,
                          },
                        },
                      }} multiline rows={4} label="YOUR MESSAGE" variant="filled" focused />
                      <Button sx={{
                          color: this.props.data.pageData.sendAMessageButtonColor
                        }}
                        onClick={this.handleMoreInfos} 
                        variant="text" 
                        startIcon={<OutboxIcon />}>{this.props.data.pageData.sendAMessageButtonText}</Button>
                    </Stack>
                </Item>}
                <Typography variant="body2" component="div" color="text.secondary">
                  {this.state.pageInfosMarkdown && <ReactMarkdown children={this.state.pageInfosMarkdown} remarkPlugins={[remarkGfm]} />}
                  {!this.state.pageInfosMarkdown && this.props.data?.pageData.pageInfos}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default TrainingContent;