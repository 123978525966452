import * as React from 'react';
import { observer } from 'mobx-react';

import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import TcaAppBarHandler from './handlers/TcaAppBarHandler';

const pages = ['Trainings', 'Pricing Policy'];

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#00add7',
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
      minHeight: 128,
    },
  }));

interface TcsAppBarProps {
    navData: any[];
}

@observer
class TcsAppBar extends React.Component<TcsAppBarProps> {

    tcaAppBarHandler:TcaAppBarHandler = new TcaAppBarHandler();

    handleOpenAnchorNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.tcaAppBarHandler.handleOpenAnchorNavMenu(event.currentTarget);
    }

    handleOpenAnchorUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.tcaAppBarHandler.handleOpenAnchorUserMenu(event.currentTarget);
    }

    handleCloseAnchorNavMenu = () => {
        this.tcaAppBarHandler.handleCloseAnchorNavMenu();
    }

    handleCloseAnchorUserMenu = () => {
        this.tcaAppBarHandler.handleCloseAnchorUserMenu();
    }
    

    render() {
        return <AppBar position="static" elevation={0}>
                <StyledToolbar>
                    <Toolbar disableGutters>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        //href="/"
                        sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontSize: 24,
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        THECODE.{'{{ACADEMY}}'}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="condensed menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={this.handleOpenAnchorNavMenu}
                        color="inherit"
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={this.tcaAppBarHandler.anchorNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(this.tcaAppBarHandler.anchorNav)}
                        onClose={this.handleCloseAnchorNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={this.handleCloseAnchorNavMenu}>
                            <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to="/"
                        //href="/"
                        sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        THECODE.{'{{ACADEMY}}'}
                    </Typography>
                    </Toolbar>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignSelf: 'flex-end' }}>
                        {this.props.navData.filter(n => n.navLabel).map((nav) => (
                        <Link key={nav.navLabel} style={{ textDecoration: 'none' }} to={nav.route?.toLowerCase()}><Button
                            onClick={this.handleCloseAnchorNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {nav.navLabel}
                        </Button></Link>
                        ))}
                    </Box>
                </StyledToolbar>
                </AppBar>
    }

}
export default TcsAppBar;