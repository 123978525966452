import * as React from 'react';
import { Link } from "react-router-dom";

import { ReactSVG } from 'react-svg';

import axios from 'axios';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import HiveIcon from '@mui/icons-material/Hive';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface BasicReviewCardProps {
    title: string,
    data?: any;
  }

export default class BasicReviewCard extends React.Component<BasicReviewCardProps> {
  
  state:any = {
    expanded: false,
    abstractMarkdown: '',
    fullTextMarkdown: '',
  }

  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  }

  componentDidMount(): void {
    const promises = [];

    if(this.props.data?.abstractMd){
      promises.push(axios.get(`${this.props.data.abstractMd}`));
    } else {
      promises.push(axios.get('/markdowndata/empty.md'));
    }

    if(this.props.data?.fullTextMd){
      promises.push(axios.get(`${this.props.data.fullTextMd}`));
    } else {
      promises.push(axios.get('/markdowndata/empty.md'));
    }

    Promise.all(promises).then(results => {
    
      console.log(results);
      
      this.setState({
        abstractMarkdown: results[0].data,
        fullTextMarkdown: results[1].data,
      });
    });
  
  }

  render() {

    return (
      <Card sx={{ flexGrow: 1 }} square={true} elevation={0}>
        <CardHeader
          component={Link}
          to={this.props.data?.linkTo}
          avatar={
            <Avatar sx={{ bgcolor: '#00add7' }} aria-label="recipe">
              <HiveIcon />
            </Avatar>
          }
          sx={{fontWeight: 700,color: 'inherit', textDecoration: 'none',}}
          title={this.props.title?.toUpperCase()}
          subheader={this.props.data?.subtitle}
        />
        <CardContent
          sx={{height:194, 
              display:'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}
        >
          {this.props.data?.svgImage && <ReactSVG 
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'height: 150');
              
            }}
            src={this.props.data.svgImage} />}
      </CardContent>
        <CardContent>
          <Typography variant="body2" component="div" color="text.secondary">
            {this.state.abstractMarkdown && <ReactMarkdown children={this.state.abstractMarkdown} remarkPlugins={[remarkGfm]} />}
            {!this.state.abstractMarkdown && this.props.data?.abstract}
            
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <ExpandMore
            expand={this.state.expanded}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
          <Typography variant="body2" component="div" color="text.secondary">
            {this.state.fullTextMarkdown && <ReactMarkdown children={this.state.fullTextMarkdown} remarkPlugins={[remarkGfm]} />}
            {!this.state.fullTextMarkdown && this.props.data?.fullText}
          </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );

  }
}