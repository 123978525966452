import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Avatar from '@mui/material/Avatar';

import Typography from '@mui/material/Typography';

import HiveIcon from '@mui/icons-material/Hive';

import BasicParagraphCard from './BasicParagraphCard';

import Grid from '@mui/material/Grid';

interface PricingContentCardProps {
    title: string,
  }

export default function PricingContentCard(props:PricingContentCardProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ flexGrow: 1 }} square={true} elevation={0}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: '#00add7' }} aria-label="recipe">
            <HiveIcon />
          </Avatar>
        }
        title={<Typography sx={{fontWeight: 600, fontSize: 24}}>{props.title?.toLocaleUpperCase()}</Typography>}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </CardContent>
        <CardContent>
        <Grid container spacing={2} columns={10}>
          <Grid item xs={10}  md={5} lg={2}>
            <BasicParagraphCard title="Spring Core Training" />
          </Grid>
          <Grid item xs={10}  md={5} lg={2}>
            <BasicParagraphCard title="Spring Core Training" />
          </Grid>
          <Grid item xs={10}  md={5} lg={2}>
            <BasicParagraphCard title="Spring Boot Training" />
          </Grid>
          <Grid item xs={10}  md={5} lg={2}>
            <BasicParagraphCard title="Spring Security Training" />
          </Grid>
          <Grid item xs={10}  md={5} lg={2}>
            <BasicParagraphCard title="Advanced Spring Training" />
          </Grid>
        </Grid>
        </CardContent>
    </Card>
  );
}