import { makeObservable, observable, action } from "mobx";


class PageHandler {

    @observable pageData:any = {};
    @observable contentData:any = {};


    constructor() {
        makeObservable(this);
    }

    @action
    public fillData(data: any) {
        this.pageData = data.page;
        this.contentData = data.content;
    }

    @action
    public cleanUpData() {
        this.pageData = {};
        this.contentData = {};
    }


}
export default PageHandler;