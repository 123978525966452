import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import CardContent from '@mui/material/CardContent';

import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import HiveIcon from '@mui/icons-material/Hive';

interface BasicParagraphCardProps {
    title: string,
  }

export default function BasicParagraphCard(props:BasicParagraphCardProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ flexGrow: 1 }} square={true} elevation={0}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: '#00add7' }} aria-label="recipe">
            <HiveIcon />
          </Avatar>
        }
        sx={{fontWeight: 700}}
        title={props.title?.toLocaleUpperCase()}
        subheader="September 18, 2026"
      />
      <CardContent
        sx={{height:194, 
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
      >
        <HiveIcon sx={{ fontSize: 150 }}/>
    </CardContent>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </CardContent>
    </Card>
  );
}