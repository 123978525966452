import { makeObservable, observable, action } from "mobx";


class TcaAppBarHandler {

    @observable anchorNav: null | HTMLElement = null;
    @observable anchorUser: null | HTMLElement = null;

    constructor() {
        makeObservable(this);
    }

    @action
    public handleOpenAnchorNavMenu(htmlElement: HTMLElement) {
        this.anchorNav = htmlElement;
    }

    @action
    public handleOpenAnchorUserMenu(htmlElement: HTMLElement) {
        this.anchorUser = htmlElement;
    }

    @action
    public handleCloseAnchorNavMenu() {
        this.anchorNav = null;
    }

    @action
    public handleCloseAnchorUserMenu() {
        this.anchorUser = null;
    }

}
export default TcaAppBarHandler;