import React from 'react';
import { observer } from 'mobx-react';

import axios from 'axios';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TrainingsContentCard from './components/TrainingsContentCard';
import TrainingContent from './components/TrainingContent';

import PageHandler from './handlers/PageHandler';

import withRouter from './hooks/hooks';

interface TrainingsProps {
    router:any,
}

@observer
class Trainings extends React.Component<TrainingsProps> {

    pageHandler = new PageHandler();

    componentDidMount(): void {

        let resourceUriPart = this.props.router.params.id? '/'+this.props.router.params.id : '';
        
        axios.get('/jsondata/trainings'+resourceUriPart+'/index.json')
            .then((response) => {
                this.pageHandler.fillData(response.data);
                document.title = 'THECODE.ACADEMY - ' + this.pageHandler.pageData.pageTitle;
            })
            .catch((error) => {
                //TODO: Something...
                console.log(error);
            });
        
    }

    componentDidUpdate(prevProps: Readonly<TrainingsProps>): void {
        
        if(prevProps.router.params.id !== this.props.router.params.id){

            this.pageHandler.cleanUpData();

            let resourceUriPart = this.props.router.params.id? '/'+this.props.router.params.id : '';

            axios.get('/jsondata/trainings'+resourceUriPart+'/index.json')
                .then((response) => {
                    this.pageHandler.fillData(response.data);
                    document.title = 'THECODE.ACADEMY - ' + this.pageHandler.pageData.pageTitle;
                })
                .catch((error) => {
                    //TODO: Something...
                    console.log(error);
                });

        }

    }

    render() {
        return (<div>
            {this.pageHandler.pageData.pageTitle && <Container maxWidth="xl">
                <Box sx={{
                    display: {xs:'block', md: 'flex'},
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    p: 1,
                    m: 1,
                    }}>
                        <Box sx={{ p:1,m:1, minWidth:320, flexGrow: 1}}>
                            {!this.props.router.params.id && <TrainingsContentCard title={this.pageHandler.pageData.pageTitle?.toUpperCase()} data={this.pageHandler} />}
                            {this.props.router.params.id && <TrainingContent course_id={this.props.router.params.id} data={this.pageHandler} />}
                        </Box>
                </Box>
            </Container>}
        </div>)
    }
}
export default withRouter(Trainings);